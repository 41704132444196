import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";

const HomeVideo = () => {
    const ref = useRef(null);

    const [player, setPlayer] = useState(null);

    useEffect(() => {
        if (ref) {
            const player = new Player(ref.current, {
                id: 648879053,
                loop: false,
                autoplay: false,
                controls: true,
            });

            setPlayer(player);
        }
    }, [ref]);

    return (
        <section className="our-categories">
            <div className="offset-lg-1 offset-xl-1 col-xl-10 col-lg-10">
                <h3
                    style={{
                        padding: "10px",
                        fontFamily: "OpusNormalCyrillic",
                    }}
                >
                    Upgrade-ийн хэрэглэгчдэд зориулсан сэтгэл судлалын үнэ
                    төлбөргүй хичээлүүд
                </h3>
                <span
                    className="rimg embed-container"
                    ref={ref}
                    style={{
                        backgroundSize: "contain",
                        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/upgrade-2e8e1.appspot.com/o/IMG_7760%20(1).jpg?alt=media&token=bda78aba-145c-41b1-86e1-17c2c104f2fb)`,
                    }}
                ></span>
                <div
                    style={{
                        // height:'20px',
                        // width:'20px',
                        // backgroundColor:'red',
                        position: "absolute",
                        bottom: "1rem",
                        right: "1rem",
                        backgroundColor: "black",
                        opacity: 0.5,
                        padding: "1rem",
                        borderRadius: "1rem",
                    }}
                ></div>
            </div>
        </section>
    );
};

export { HomeVideo };
