import React, { useContext, useEffect } from 'react';
import { Layout, IconEndBracket, GoogleForm, FacebookForm } from '../components';
import { useFirebase } from '../firebase';
import { useCollection } from '../hooks/use-collection';
import { AuthContext } from '../providers/auth-user-provider';

export const Courses = () => {
    const { ready, user } = useContext(AuthContext);
    const { uid } = user || {}

    const { data: courses } = useCollection({ path: `/courses/` })

    console.log(courses)

    return (
        <Layout>
            <div className='h100 flex flex-col justify-center items-center'>
                <div>All courses</div>

                {
                    courses &&
                    courses.map(item => (<div>{item.name} - {item.teacher.name}</div>))
                }
            </div>
        </Layout>
    )
}