import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useFirebase } from "../../firebase";
import { useDoc } from "../../hooks/use-collection";
import congtsAnimationData from "../../images/congts.json";
import animationData from "../../images/loading.json";
import { AuthContext } from "../../providers/auth-user-provider";
import { ModalContext } from "../../providers/modal-provider";
import "./couponModal.css";

export const CouponModal = ({ close }) => {
    const { ready, user } = useContext(AuthContext);
    const { setModal } = useContext(ModalContext);
    const { uid } = user || {};

    const { functions } = useFirebase();
    const [invoiceId, setInvoiceId] = useState(null);
    const [invoiceData, setInvoiceData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const { data: invoice, loading: loadingInvoice } = useDoc(
        `users/${uid}/invoices/${invoiceId}`,
        true
    );
    const [showCouponIds, setShowCouponIds] = useState(false);

    const disableClick = (e) => {
        e.stopPropagation();
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        if (invoice && invoice.status === "complete") {
            setIsPaid(true);
        }
    }, [invoice]);

    const payQpay = () => {
        setStep(Steps[2]);
        setLoading(true);

        var buyCoupon = functions.httpsCallable("buyCoupon");
        buyCoupon({
            couponType: coupon.type,
            packageType,
        })
            .then((result) => {
                setLoading(false);
                setInvoiceId(result.data.invoiceId);
                setInvoiceData(result.data);
                console.log(result);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const PACKAGES = ["one", "three", "all"];
    const PACKAGES_DATA = [
        {
            type: "one",
            amount: 90000,
            name: "1 хичээл",
        },
        {
            type: "three",
            amount: 150000,
            name: "3 хичээл",
        },
        {
            type: "all",
            amount: 210000,
            name: "Бүх хичээл",
        },
    ];
    const COUPON_DATA = [
        {
            type: "two",
            amount: 20,
            count: 2,
            name: "Хоёр хүн",
        },
        {
            type: "three",
            amount: 30,
            count: 3,
            name: "Гурван хүн",
        },
        {
            type: "four",
            amount: 40,
            count: 4,
            name: "Дөрвөн хүн",
        },
    ];
    const [packageType, setPackageType] = useState(PACKAGES[0]);
    const [coupon, setCoupon] = useState(COUPON_DATA[0]);
    const Steps = ["coupon", "package", "payment"];
    const [step, setStep] = useState(Steps[0]);

    const SelectedPackageItem = () => {
        return (
            <div>
                <h5>Багц</h5>
                <ul className="select-pack list-unstyled">
                    <li>
                        {PACKAGES_DATA.filter(
                            ({ type }) => type === packageType
                        ).map((Package) => (
                            <li>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name={"exampleRadios" + Package.type}
                                        id={"exampleRadios" + Package.type}
                                    />
                                    <label>
                                        <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                            <span>{Package.name}:</span>
                                            <strong>
                                                {parseInt(
                                                    Package.amount *
                                                        coupon.count *
                                                        ((100 - coupon.amount) /
                                                            100)
                                                )}
                                                ₮
                                            </strong>
                                        </p>
                                    </label>
                                </div>
                            </li>
                        ))}
                    </li>
                </ul>
            </div>
        );
    };

    const SelectedCoupon = () => {
        return (
            <div style={{ marginTop: "-20px" }}>
                <h5>Ваучер</h5>
                <ul className="select-pack list-unstyled">
                    <li>
                        <div className="form-check">
                            <input
                                type="radio"
                                name={"exampleRadios" + coupon.type}
                                id={"exampleRadios" + coupon.type}
                            />
                            <label>
                                <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                    <span>{coupon.name}:</span>
                                    <strong>
                                        {coupon.count} хүн - {coupon.amount}%
                                    </strong>
                                </p>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        );
    };

    const QPayItem = () => {
        if (!invoiceData || !invoiceData.invoice) {
            return <div></div>;
        }

        const {
            invoice: { qr_image },
        } = invoiceData;

        if (isPaid) {
            return <div></div>;
        }

        return (
            <div>
                <h5 className="modal-title text-center" id="exampleModalLabel">
                    Та доорх QR кодыг уншуулж төлбөрөө төлнө үү
                </h5>
                <img src={`data:image/png;base64,${qr_image}`} width={"100%"} />
            </div>
        );
    };

    const InvoiceStatus = () => {
        if (!invoice || loadingInvoice === true) {
            return <div></div>;
        }

        return (
            <div className="d-flex align-items-center flex-wrap text-center justify-content-center">
                <span>Төлбөр хүлээгдэж байна</span>
            </div>
        );
    };

    const InvoicePaid = () => {
        const data = {
            loop: false,
            autoplay: true,
            animationData: congtsAnimationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        return (
            <div>
                <Lottie
                    options={data}
                    height={"200px"}
                    width={"200px"}
                    isStopped={false}
                    isPaused={false}
                    eventListeners={[
                        {
                            eventName: "complete",
                            callback: () => {
                                setTimeout(() => {
                                    setShowCouponIds(true);
                                }, 1000);
                            },
                        },
                    ]}
                />
                <h4 className="modal-title text-center" id="exampleModalLabel">
                    Төлбөр амжилттай төлөгдлөө
                </h4>
            </div>
        );
    };

    console.log(showCouponIds);

    return (
        <div
            className="modal log-reg"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
        >
            <div className="modal-dialog modal-sm">
                <div className="modal-content" onClick={disableClick}>
                    <div className="modal-header">
                        <h4
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Купон худалдаж авах
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={close}
                        >
                            <span aria-hidden="true">
                                <i className="lni lni-close"></i>
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {step === Steps[0] && (
                            <div>
                                <h5>Ваучер сонгох</h5>
                                <ul className="select-pack list-unstyled">
                                    {COUPON_DATA.map((Coupon) => (
                                        <li
                                            onClick={() => {
                                                console.log(Coupon);
                                                setCoupon(Coupon);
                                            }}
                                        >
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios2"
                                                    id={
                                                        "exampleRadios2" +
                                                        Coupon.type
                                                    }
                                                    checked={
                                                        coupon.type ===
                                                        Coupon.type
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    for={
                                                        "exampleRadios2" +
                                                        Coupon.type
                                                    }
                                                >
                                                    <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                                        <span>
                                                            {Coupon.name}:
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            <strong>
                                                                {Coupon.amount}%
                                                            </strong>
                                                        </div>
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <button
                                    type="submit"
                                    onClick={() => {
                                        setStep(Steps[1]);
                                    }}
                                    className="btn btn-main btn-block"
                                >
                                    Үргэлжлүүлэх
                                </button>
                            </div>
                        )}
                        {step === Steps[1] && (
                            <div>
                                <h5>Багц сонгох</h5>
                                <ul className="select-pack list-unstyled">
                                    {PACKAGES_DATA.map((Package) => (
                                        <li
                                            onClick={() => {
                                                console.log(Package);
                                                setPackageType(Package.type);
                                            }}
                                        >
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id={
                                                        "exampleRadios" +
                                                        Package.type
                                                    }
                                                    checked={
                                                        packageType ===
                                                        Package.type
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    for={
                                                        "exampleRadios" +
                                                        Package.type
                                                    }
                                                >
                                                    <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                                        <span>
                                                            {Package.name}:
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            <strong
                                                                style={{
                                                                    textDecoration:
                                                                        "line-through red",
                                                                    textDecorationThickness:
                                                                        "0.15em",
                                                                }}
                                                            >
                                                                {Package.amount}
                                                                ₮
                                                            </strong>
                                                            <strong>
                                                                {parseInt(
                                                                    (Package.amount *
                                                                        (100 -
                                                                            coupon.amount)) /
                                                                        100
                                                                )}
                                                                ₮
                                                            </strong>
                                                        </div>
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <button
                                    type="submit"
                                    onClick={() => {
                                        payQpay();
                                    }}
                                    className="btn btn-main btn-block"
                                >
                                    Үргэлжлүүлэх
                                </button>
                            </div>
                        )}
                        {step === Steps[2] && (
                            <div>
                                <SelectedPackageItem />
                                <SelectedCoupon />
                                {loading && (
                                    <h4
                                        className="modal-title text-center"
                                        id="exampleModalLabel"
                                    >
                                        <Lottie
                                            options={defaultOptions}
                                            height={"200px"}
                                            width={"200px"}
                                            isStopped={false}
                                            isPaused={false}
                                        />
                                    </h4>
                                )}
                                {!loading && <QPayItem />}
                                {showCouponIds && (
                                    <div style={{ marginTop: "-20px" }}>
                                        <h5> Ваучер код </h5>
                                        {invoice.couponIds.map((item) => (
                                            <h6 className="text-center">
                                                {" "}
                                                {item}{" "}
                                            </h6>
                                        ))}
                                    </div>
                                )}
                                {invoice &&
                                    !showCouponIds &&
                                    (isPaid ? (
                                        <InvoicePaid />
                                    ) : (
                                        <InvoiceStatus />
                                    ))}
                            </div>
                        )}
                        {(step === Steps[0] ||
                            step === Steps[1] ||
                            step === Steps[2]) &&
                            !showCouponIds && (
                                <p className="policy">
                                    <h5> Санамж: </h5>
                                    <p style={{ textAlign: "center" }}>
                                        Ваучерээр хөнгөлөлт эдэлж буй хүн бүр
                                        адилхан багц сонгох ёстойг анхаарна уу.
                                    </p>
                                    <p
                                        style={{
                                            textAlign: "center",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {" "}
                                        Жишээлбэл та гурван хичээлийн багц
                                        сонгосон бол таны ваучерийг ашиглан
                                        хөнгөлөлт эдлэх хүн бүр адилхан гурван
                                        хичээлийн багц сонгох ёстойг анхаарна
                                        уу. Харин тухайн багцад дагалдах
                                        хичээлүүдээ өөрөө сонгож болно.
                                    </p>
                                </p>
                            )}
                        {showCouponIds && (
                            <p className="policy">
                                <h5> Санамж: </h5>
                                <p style={{ textAlign: "center" }}>
                                    Санамсар болгоомжгүйн улмаас ваучерийн кодоо
                                    алдсан буюу бусдад задруулсан тохиолдолд
                                    үүсэх аливаа эрсдэлийг манай байгууллага
                                    хариуцахгүй болно.
                                </p>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
