import { useEffect, useState } from 'react';
import { useFirebase } from '../firebase';


export const useCollection = ({ path, load = false, orders = ['id'], limit = 0, userId = '', email = '', name = '' }) => {
    const [data, setData] = useState([]);
    const { firestore } = useFirebase();

    useEffect(() => {
        if (!load) {
            return;
        }

        if (firestore && path.split('/').length % 2) {
            let collection = firestore.collection(path)
            collection = orders.reduce((collection, field) => {
                if (field[0] == '-') {
                    return collection.orderBy(field.slice(1), 'desc')
                }
                return collection.orderBy(field)
            }, collection)

            if (email.length) {
                collection = collection.where('email', '==', email)
            }
            if (userId.length) {
                collection = collection.where('userId', '==', userId)
            }
            if (name.length) {
                collection = collection.where('name', '>=', name)
            }

            if (limit) {
                collection = collection.limit(limit)
            }

            const unsubscribe = collection.onSnapshot((s) => {
                const list = s.docs.map((doc) => ({ ...doc.data(), docId: doc.id }))

                setData(list);
            })

            return () => unsubscribe();
        }
    }, [firestore, path, load, userId, email, name]);

    const createDoc = (id, data) => {
        firestore.collection(path).doc(id).set(data);
    }

    const updateDoc = (id, data) => {
        firestore.collection(path).doc(id).set(data, { merge: true });
    }

    const deleteDoc = (id) => {
        firestore.collection(path).doc(id).delete();
    }


    return { data, createDoc, updateDoc, deleteDoc };
}

export const useDoc = (path, real = false) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { firestore } = useFirebase();

    useEffect(() => {
        if (firestore && path && (path.split('/')).length % 2 == 0 && path[path.length - 1] != '/') {
            setLoading(true)

            if (real) {
                firestore.doc(path)
                    .onSnapshot(function (doc) {
                        setLoading(false)

                        if (!doc.exists) {
                            return {}
                        }

                        setData({ docId: doc.id, ...doc.data() })
                    })

                return
            }

            firestore.doc(path).get()
                .then((doc) => {
                    setLoading(false)

                    if (!doc.exists) {
                        return {}
                    }

                    const data = { docId: doc.id, ...doc.data() }

                    setData(data)
                })
                .catch(error => {
                    setLoading(false)
                    setData(null)
                })
        }
    }, [firestore, path]);

    return { data, loading }
}