import React, { useState } from "react";
import Swiper from "swiper";

import webPic001 from "./../images/comments/new/001.png";
import webPic002 from "./../images/comments/new/002.png";
import webPic003 from "./../images/comments/new/003.png";
import webPic004 from "./../images/comments/new/004.png";
import webPic005 from "./../images/comments/new/005.png";
import webPic006 from "./../images/comments/new/006.png";
import webPic007 from "./../images/comments/new/007.png";
import webPic008 from "./../images/comments/new/008.png";

const slides = [
    webPic001,
    webPic002,
    webPic003,
    webPic004,
    webPic005,
    webPic006,
    webPic007,
    webPic008,
];

const PrettyTestimonials = () => {
    //   const categoryPlayerRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    //   const [isAutoplay, setAutoplay] = useState(false);

    //   const [playerTag, setPlayer] = useState(null);

    const changeSlide = (newIndex) => {
        if (newIndex < 0) {
            newIndex = slides.length - 1;
        }
        if (newIndex === slides.length) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    const SingleSlide = (props) => {
        const { index } = props;

        const nextIndex = (index + 1) % slides.length;
        const nextIndex2 = (index + 2) % slides.length;
        return (
            <div className="d-flex align-items-center justify-content-center flex-lg-row flex-column-reverse row">
                <div className="col-lg-4 col-sm-6">
                    <img className="img-fluid" src={slides[index]} alt="" />
                </div>

                <div className="col-lg-4 col-sm-6">
                    <img className="img-fluid" src={slides[nextIndex]} alt="" />
                </div>

                <div className="col-lg-4 col-sm-6">
                    <img
                        className="img-fluid"
                        src={slides[nextIndex2]}
                        alt=""
                    />
                </div>
                {/* <img
                    src="images/demo/2.png"
                    className="img-fluid mobile-img"
                    alt=""
                />
                <img src="images/demo/2.jpg" className="img-fluid" alt="" /> */}
            </div>
        );
    };

    //   useEffect(() => {
    //     if (categoryPlayerRef) {
    //       if (playerTag) {
    //         playerTag.destroy();
    //       }

    //       const player = new Player(categoryPlayerRef.current, {
    //         id: slides[activeIndex].videoId,
    //         autoplay: isAutoplay,
    //       });

    //       player.on("ended", function () {
    //         player.destroy();
    //       });
    //       setPlayer(player);
    //     }
    //   }, [categoryPlayerRef, activeIndex]);

    const swiper = new Swiper("swiper-category", {});

    return (
        <section className="testimony">
            <div className="container">
                <h2 className="text-center">Хэрэглэгчдийн сэтгэгдэл</h2>
                <div className="slide-wrap">
                    <div className="swiper-container swiper-testimony">
                        <div className="swiper-wrapper">
                            {/* {slides.map((_el, ind) => (
                                <SingleSlide
                                    key={`singleSlide-${ind}`}
                                    index={ind}
                                />
                            ))} */}
                            <SingleSlide
                                key={`singleSlide-${activeIndex}`}
                                index={activeIndex}
                            />
                        </div>
                    </div>
                    <div className="swiper-pagination"></div>
                    <button
                        className="swiper-button swiper-button-next testimony-next"
                        onClick={() => changeSlide(activeIndex + 1)}
                    ></button>
                    <button
                        className="swiper-button swiper-button-prev testimony-prev"
                        onClick={() => changeSlide(activeIndex - 1)}
                    ></button>
                </div>
            </div>
        </section>
    );
};

export { PrettyTestimonials };
