import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";

const TopRegister = () => {
    const ref = useRef(null);

    const [isMuted, setIsMuted] = useState(true);
    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (ref) {
            const player = new Player(ref.current, {
                id: 651489239,
                loop: true,
                autoplay: true,
                controls: false,
                muted: true,
                audio: 0,
            });

            setPlayer(player);
        }
    }, [ref]);

    const toggleSound = () => {
        if (player) {
            player.setMuted(!isMuted);
        }
        setIsMuted(!isMuted);
    };

    return (
        <section className="top-register" style={{ paddingTop: 0 }}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="offset-xl-1 col-xl-3 offset-lg-0 col-lg-4 order-lg-1 order-2">
                        <div className="front-register">
                            <div className="d-none d-lg-block">
                                <img
                                    className="mx-auto d-block homepage-logo"
                                    src="logo-rainbow.png"
                                    alt="Upgrade"
                                />
                            </div>
                            <h1
                                className="text-uppercase text-center"
                                style={{
                                    fontSize: "1.6rem",
                                    fontFamily: "Raleway",
                                    fontWeight: 100,
                                    // fontStyle:'italic',
                                    // fontWeight:'bold'
                                }}
                            >
                                {/* Элсэлтийн Ерөнхий Шалгалтад бэлтгэх онлайн
                                хичээлүүдийг{" "}
                                <span
                                    style={{
                                        color: "rgb(242, 68, 98)",
                                        fontWeight: 500,
                                    }}
                                >
                                    Upgrade
                                </span>
                                -ээс */}
                                БҮТЭН ХОЁР ЖИЛИЙН АГУУЛГЫГ ЕРДӨӨ 50 ЦАГИЙН ДОТОР
                                СУДЛААД{" "}
                                <span
                                    style={{
                                        color: "rgb(242, 68, 98)",
                                        fontWeight: 500,
                                    }}
                                >
                                    ЭЕШ-ДАА
                                </span>{" "}
                                ӨНДӨР АМЖИЛТ ҮЗҮҮЛЭЭРЭЙ.
                                {/* This is very cool latin text */}
                            </h1>
                        </div>
                    </div>

                    <div className="col-xl-8 col-lg-8 pr-lg-0 order-lg-2 order-1">
                        {/* <iframe
              width="100%"
              height="560"
              src="https://www.youtube.com/embed/0QN2QH7d2Ho?autoplay=1&loop=1&autopause=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}

                        <span
                            className="rimg embed-container"
                            ref={ref}
                            style={{
                                backgroundSize: "contain",
                                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/upgrade-2e8e1.appspot.com/o/HOMEPAGEPICLOGO-min.PNG?alt=media&token=ece2393a-e17e-4a89-af2f-42a1c03f1855)`,
                            }}
                        ></span>
                        <div
                            style={{
                                // height:'20px',
                                // width:'20px',
                                // backgroundColor:'red',
                                position: "absolute",
                                bottom: "1rem",
                                right: "1rem",
                                backgroundColor: "black",
                                opacity: 0.5,
                                padding: "1rem",
                                borderRadius: "1rem",
                            }}
                        >
                            <button
                                style={{
                                    // height:'20px',
                                    // width:'20px',
                                    // backgroundColor:'red',
                                    position: "absolute",
                                    bottom: "1rem",
                                    right: "1rem",
                                    backgroundColor: "black",
                                    opacity: 0.5,
                                    padding: "1rem",
                                    borderRadius: "1rem",
                                }}
                                onClick={() => toggleSound()}
                            >
                                {isMuted ? (
                                    <i
                                        className="lni lni-volume-mute"
                                        style={{
                                            fontSize: "2rem",
                                            color: "white",
                                        }}
                                    ></i>
                                ) : (
                                    <i
                                        className="lni lni-volume-medium"
                                        style={{
                                            fontSize: "2rem",
                                            color: "white",
                                        }}
                                    ></i>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { TopRegister };
