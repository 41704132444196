import React, { useContext, useEffect } from "react";
import { SetSection } from "../components/detail/setSection";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { MyCoursesPage } from "../components/home/myCoursesPage";
import { PrettyCategories } from "../components/prettyCategories";
import { PrettyTeachers } from "../components/prettyTeachers";
import { PrettyTestimonials } from "../components/prettyTestimonials";
import { Questions } from "../components/questions";
import { Statistics } from "../components/statistics";
import { TopRegister } from "../components/topRegister";
import { useFirebase } from "../firebase";
import { AuthContext } from "../providers/auth-user-provider";
import { HomeVideo } from "../components/homeVideo";

import webPic001 from "./../images/web_pics_001.jpg";
import webPic002 from "./../images/web_pics_002.jpg";

import image1 from "./../images/homepage_details/005.jpg";

import image2 from "./../images/homepage_details/007.jpg";
import image3 from "./../images/homepage_details/app_1.jpg";
import image4 from "./../images/homepage_details/app_2.jpg";

export const HomeDefault = () => {
    const { ready, user } = useContext(AuthContext);
    const { firestore } = useFirebase();

    useEffect(() => {
        if (user) {
            const {
                uid,
                displayName = null,
                email = null,
                photoURL = null,
                metadata: { creationTime = null },
            } = user || { metadata: {} };

            firestore.doc(`users-data/${uid}`).set(
                {
                    uid,
                    name: displayName,
                    email,
                    photoURL,
                    createdAt: new Date(creationTime),
                },
                { merge: true }
            );
        }
    }, [user]);

    return (
        <div className="wrap">
            <Header />

            <TopRegister />

            <Statistics />

            <HomeVideo />

            <section className="top-register">
                <div className="container-fluid">
                    <div className="row align-items-center ">
                        <div className="offset-lg-1 col-lg-10">
                            <div className="row">
                                <div className="col-lg-5">
                                    <img
                                        alt=""
                                        src={image1}
                                        style={{ width: "100%", marginTop: 20 }}
                                    />
                                </div>
                                <div className="col-lg-7">
                                    <img
                                        alt=""
                                        src={image2}
                                        style={{ width: "100%", marginTop: 20 }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img
                                        alt=""
                                        src={image3}
                                        style={{ width: "100%", marginTop: 20 }}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <img
                                        alt=""
                                        src={image4}
                                        style={{ width: "100%", marginTop: 20 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PrettyTestimonials />

            <MyCoursesPage user={user} />
            {/* <FriendsSetSetSection /> */}
            {/* <PrettyTeachers /> */}

            {/* <SetSection /> */}
            <PrettyCategories />
            {/* <AppComingSoon /> */}
            <Questions />
            <Footer />
        </div>
    );
};
