import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useFirebase } from '../../firebase'
import { AuthContext } from '../../providers/auth-user-provider'
import { FirebaseContext } from '../../providers/firebase-collection'

export const MyCourses = () => {
    const { coursesData, loadingCourses, setLoad, myCoursesData, loadingMyCourses } = useContext(FirebaseContext)
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const { ready, user } = useContext(AuthContext)

    const { functions } = useFirebase()

    useEffect(() => {
        if (user) {
            setLoad({ courses: true, myCourses: true })
        }

        if (!functions) {
            return
        }
    }, [user])

    const CourseItem = (course, index) => {
        const { courseId } = course

        const CourseInfo = _.find(coursesData, { docId: courseId })

        if (CourseInfo) {
            const { name, teacher, image } = CourseInfo

            return (
                <div className="col-lg-4">
                    <div className="single-lesson">
                        <a href={`/detail/${courseId}`}>
                            <span className="rimg" style={{ backgroundImage: `url(${image})` }} ></span>
                            <h5>{name}</h5>
                            <p className="name">{teacher.name}</p>
                        </a>
                    </div>
                </div>

            )
        } else {
            return <></>
        }

    }

    if (user && ready && myCoursesData.length) {
        return (
            <div className="lesson-list" style={{ margin: 0, padding: 0, borderTop: 0 }}>
                <h2 className="text-center">Миний хичээлүүд</h2>
                {
                    (!loading && !courses.length && user && !user.premium) &&
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div className="no-lesson">
                                <p>Одоогоор хичээл аваагүй байна</p>
                                <a className="btn btn-main" data-toggle="modal" data-target="#paymentModal">Хичээлийн багц авах</a>
                            </div>
                        </div>
                    </div>
                }
                {
                    (!loading && !courses.length && user && user.premium) &&
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <div className="no-lesson">
                                <p>Одоогоор хичээл эхлүүлээгүй байна</p>
                                <p style={{ fontSize: '16px' }}>Та өөрт тохирсон хичээлээ сонгоод үзээрэй</p>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    {
                        myCoursesData.map(CourseItem)
                    }
                </div>
            </div >
        )
    } else {
        return (
            <div></div>
        )
    }

}
