import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { TeacherTop } from '../components/detail/teacherTop';
import { TrailerIntro } from '../components/detail/trailerIntro';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { Questions } from '../components/questions';
import { useFirebase } from '../firebase';
import { useDoc } from '../hooks/use-collection';
import { AuthContext } from '../providers/auth-user-provider';
import { FirebaseContext } from '../providers/firebase-collection';

export const Detail = () => {
    const { courseId } = useParams()
    const { ready, user } = useContext(AuthContext);
    const { lessonsData, setLoad, lessonDataDetail } = useContext(FirebaseContext);
    const { data: course } = useDoc(`courses/${courseId}`)
    const [paid, setPaid] = useState(null)
    const [myCourse, setMyCourse] = useState(null)
    const { firestore } = useFirebase()

    useEffect(() => {
        setLoad({ lessons: { courseId } })

        if (firestore && user && user.uid && courseId) {
            firestore.doc(`users/${user.uid}/courses/${courseId}`).get()
                .then(doc => {
                    if (!doc.exists) {
                        setPaid(false)
                        return
                    }

                    const data = doc.data()
                    if (data.expiredAt.seconds * 1000 - (new Date()).getTime()) {
                        setPaid(true)
                        setMyCourse(data)
                    } else {
                        setPaid(false)
                    }
                })
        }

        if (ready && !user) {
            setPaid(false)
        }
    }, [courseId, user, firestore])

    return (
        <div className="wrap">
            <Header />
            <TeacherTop {...course} user={user} paid={paid} myCourse={myCourse} />
            <TrailerIntro user={user} courseId={courseId} course={course} lessons={paid ? lessonDataDetail : lessonsData} paid={paid} myCourse={myCourse} />
            <Questions />
            <Footer />
        </div >
    )
}