import React, { useContext } from 'react'
import { ModalContext } from '../providers/modal-provider'
import gImage from './../images/8.png'
import fbImage from './../images/9.png'
import logoWhite from './../images/logo_white.png'
import { SignInSocial } from './signInSocial'


const SigninModal = ({ close }) => {
    const { signInWithFacebook, signInWithGoogle } = SignInSocial()
    const { setModal } = useContext(ModalContext)

    const disableClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="modal log-reg" onClick={close}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content" onClick={disableClick}>
                    <div className="modal-header">
                        <h4 className="modal-title text-center">Нэвтрэх</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                            <span aria-hidden="true">
                                <i className="lni lni-close"></i>
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-center" style={{ paddingBottom: '40px' }}>
                            <img src={logoWhite} className="img-fluid" style={{ maxHeight: '150px' }} alt="Logo" />
                        </div>
                        <ul className="socials list-unstyled">
                            <li className="facebook">
                                <a onClick={() => signInWithFacebook(close)} href="#">
                                    <img src={fbImage} alt="" />
                                    <span>Facebook-ээр нэвтрэх</span>
                                </a>
                            </li>
                            <li className="google">
                                <a onClick={() => signInWithGoogle(close)} href="#">
                                    <img src={gImage} alt="" />
                                    <span>Google-ээр нэвтрэх</span>
                                </a>
                            </li>
                        </ul>
                        <div className="text-center">
                            <hr></hr>
                        </div>
                        <p className="policy">Та нэвтрэх товчийг дарсанаар манай үйлчилгээний нөхцөл <a onClick={() => { setModal('policy') }}>ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</a> - ийг хүлээн зөвшөөрсөнд тооцно.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { SigninModal }
