import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router";
import { useFirebase } from "../../firebase";
import { useDoc } from "../../hooks/use-collection";
import congtsAnimationData from "../../images/congts.json";
import animationData from "../../images/loading.json";
import { AuthContext } from "../../providers/auth-user-provider";
import { FirebaseContext } from "../../providers/firebase-collection";
import { ModalContext } from "../../providers/modal-provider";
import "./paymentModal.css";

export const PaymentModal = ({ close }) => {
    const { ready, user } = useContext(AuthContext);
    const { setModal } = useContext(ModalContext);
    const { uid } = user || {};

    const { courseId } = useParams();
    const { functions } = useFirebase();
    const [invoiceId, setInvoiceId] = useState(null);
    const [invoiceData, setInvoiceData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const { data: invoice, loading: loadingInvoice } = useDoc(
        `users/${uid}/invoices/${invoiceId}`,
        true
    );

    const disableClick = (e) => {
        e.stopPropagation();
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        if (invoice && invoice.status == "complete") {
            setIsPaid(true);
        }
    }, [invoice]);

    const payQpay = () => {
        setLoading(true);

        var payQpay = functions.httpsCallable("payQpay");
        payQpay({
            courseIds: selectedCourses,
            packageType,
        })
            .then((result) => {
                setLoading(false);
                setInvoiceId(result.data.invoiceId);
                setInvoiceData(result.data);
                console.log(result);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const paySocial = () => {
        setLoading(true);

        var paySocialPay = functions.httpsCallable("paySocialPay");
        paySocialPay({
            courseIds: [courseId],
        })
            .then((result) => {
                setLoading(false);
                setInvoiceId(result.data.invoiceId);
                console.log(result);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const payBank = () => {
        setLoading(true);

        var payBank = functions.httpsCallable("payBank");
        payBank({
            courseIds: [courseId],
        })
            .then((result) => {
                setLoading(false);
                setInvoiceId(result.data.invoiceId);
                console.log(result);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const PACKAGES = ["one", "three", "all"];
    const PACKAGES_DATA = [
        {
            type: "one",
            amount: 90000,
            name: "1 хичээл",
        },
        {
            type: "three",
            amount: 150000,
            name: "3 хичээл",
        },
        {
            type: "all",
            amount: 210000,
            name: "Бүх хичээл",
        },
    ];
    const [packageType, setPackageType] = useState(PACKAGES[0]);
    const PaymentType = ["khan", "qpay", "socialpay"];
    const [paymentType, setPaymentType] = useState(PaymentType[0]);
    const Steps = ["package", "course", "payment", "pendingPayment"];
    const [step, setStep] = useState(Steps[0]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const { coursesData, load, setLoad } = useContext(FirebaseContext);

    useEffect(() => {
        setLoad({ courses: true });

        return () => {
            setLoad({ courses: false });
        };
    }, []);

    const invertIdDebounce = _.debounce((docId) => {
        console.log(docId);

        if (!selectedCourses.includes(docId)) {
            let courseIds = [docId, ...selectedCourses];
            if (packageType == PACKAGES[0]) {
                courseIds = courseIds.slice(0, 1);
            } else {
                courseIds = courseIds.slice(0, 3);
            }
            setSelectedCourses(courseIds);
        } else {
            let courseIds = selectedCourses.filter((item) => item != docId);
            setSelectedCourses(courseIds);
        }
    }, 300);

    const invertId = (docId) => invertIdDebounce(docId);

    const submitChooseLesson = () => {
        if (packageType == PACKAGES[1] && selectedCourses.length != 3) {
            setErrorMessage("* Мэргэшүүлсэн багц нь 3 хичээл сонгох боломжтой");
        } else if (packageType == PACKAGES[0] && selectedCourses.length != 1) {
            setErrorMessage("* Хүслийн багц нь 1 хичээл сонгох боломжтой");
        } else {
            payQpay();
            setPaymentType(PaymentType[1]);
            setStep(Steps[3]);
            setErrorMessage(null);
        }
    };

    const buyAll = () => {
        payQpay();
        setPaymentType(PaymentType[1]);
        setStep(Steps[3]);
        setErrorMessage(null);
    };

    const submit = () => {
        console.log(PaymentType);
        console.log(paymentType);

        if (paymentType == PaymentType[0]) {
            payBank();
        }
        if (paymentType == PaymentType[1]) {
            payQpay();
        }
        if (paymentType == PaymentType[2]) {
            paySocial();
        }

        setStep(Steps[3]);
    };

    const SelectedPackageItem = () => {
        return (
            <div>
                <h5>Багц</h5>
                <ul className="select-pack list-unstyled">
                    <li>
                        {PACKAGES_DATA.filter(
                            ({ type }) => packageType == type
                        ).map((Package) => (
                            <li>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        name={"exampleRadios" + Package.type}
                                        id={"exampleRadios" + Package.type}
                                    />
                                    <label>
                                        <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                            <span>{Package.name}:</span>
                                            <strong>{Package.amount}₮</strong>
                                        </p>
                                    </label>
                                </div>
                            </li>
                        ))}
                    </li>
                </ul>
            </div>
        );
    };

    const QPayItem = () => {
        if (!invoiceData || !invoiceData.invoice) {
            return <div></div>;
        }

        const {
            invoice: { qr_image },
        } = invoiceData;

        if (isPaid) {
            return <div></div>;
        }

        return (
            <div>
                <h5 className="modal-title text-center" id="exampleModalLabel">
                    Та доорх QR кодыг уншуулж төлбөрөө төлнө үү
                </h5>
                <img src={`data:image/png;base64,${qr_image}`} width={"100%"} />
            </div>
        );
    };

    const InvoiceStatus = () => {
        if (!invoice || loadingInvoice == true) {
            return <div></div>;
        }

        return (
            <div className="d-flex align-items-center flex-wrap text-center justify-content-center">
                <span>Төлбөр хүлээгдэж байна</span>
            </div>
        );
    };

    const InvoicePaid = () => {
        const data = {
            loop: false,
            autoplay: true,
            animationData: congtsAnimationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        return (
            <div>
                <Lottie
                    options={data}
                    height={"200px"}
                    width={"200px"}
                    isStopped={false}
                    isPaused={false}
                    eventListeners={[
                        {
                            eventName: "complete",
                            callback: () => {
                                setTimeout(() => {
                                    window.location.reload(false);
                                }, 1000);
                            },
                        },
                    ]}
                />
                <h4 className="modal-title text-center" id="exampleModalLabel">
                    Төлбөр амжилттай төлөгдлөө
                </h4>
            </div>
        );
    };

    const [couponId, setCouponId] = useState();
    const [showCoupon, setShowCoupon] = useState(false);
    const [loadingCoupon, setLoadingCoupon] = useState(null);
    const [couponError, setCouponError] = useState(null);

    const submitCoupon = () => {
        console.log(couponId, invoice);
        if (loadingCoupon) {
            return;
        }

        var couponUse = functions.httpsCallable("couponusefunction");

        setLoadingCoupon(true);
        couponUse({
            invoiceId: invoice.docId,
            couponId,
        })
            .then((result) => {
                setLoadingCoupon(false);

                if (result.data.response == "success") {
                    setShowCoupon(false);
                } else {
                    setCouponError(result.data.message);
                }
            })
            .catch((error) => {
                alert(error);
                setLoadingCoupon(false);
            });
    };

    return (
        <div
            className="modal log-reg"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
        >
            <div className="modal-dialog modal-sm">
                <div className="modal-content" onClick={disableClick}>
                    <div className="modal-header">
                        <h4
                            className="modal-title text-center"
                            id="exampleModalLabel"
                        >
                            Төлбөр төлөх
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={close}
                        >
                            <span aria-hidden="true">
                                <i className="lni lni-close"></i>
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {step == Steps[0] && (
                            <div>
                                <h5>Багц сонгох</h5>
                                <ul className="select-pack list-unstyled">
                                    {PACKAGES_DATA.map((Package) => (
                                        <li
                                            onClick={() => {
                                                console.log(Package);
                                                setPackageType(Package.type);
                                            }}
                                        >
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id={
                                                        "exampleRadios" +
                                                        Package.type
                                                    }
                                                    checked={
                                                        packageType ==
                                                        Package.type
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    for={
                                                        "exampleRadios" +
                                                        Package.type
                                                    }
                                                >
                                                    <p className="payment-info d-flex align-items-center justify-content-between flex-wrap">
                                                        <span>
                                                            {Package.name}:
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            {/* <strong style={{ textDecoration: 'line-through red', textDecorationThickness: '0.15em' }}>{Package.amount}₮</strong> */}
                                                            <strong>
                                                                {Package.amount}
                                                                ₮
                                                            </strong>
                                                        </div>
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <button
                                    type="submit"
                                    onClick={() => {
                                        if (
                                            packageType == PACKAGES_DATA[2].type
                                        )
                                            buyAll();
                                        else setStep(Steps[1]);
                                    }}
                                    className="btn btn-main btn-block"
                                >
                                    Үргэлжлүүлэх
                                </button>
                            </div>
                        )}
                        {step == Steps[1] && (
                            <div>
                                <h5>Хичээл сонголт</h5>
                                <ul className="select-pack list-unstyled">
                                    {coursesData.map((course) => (
                                        <li>
                                            <div
                                                className="form-check"
                                                onClick={() => {
                                                    invertId(course.docId);
                                                }}
                                            >
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="exampleRadios"
                                                    id={
                                                        "exampleRadio" +
                                                        course.docId
                                                    }
                                                    checked={selectedCourses.includes(
                                                        course.docId
                                                    )}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    for={
                                                        "exampleRadio" +
                                                        course.docId
                                                    }
                                                    style={{
                                                        paddingBottom: "0.4rem",
                                                        paddingTop: "0.4rem",
                                                    }}
                                                >
                                                    <p className="payment-info d-flex align-items-center flex-wrap">
                                                        <strong>
                                                            {course.name}
                                                        </strong>
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                {errorMessage && <h6>{errorMessage}</h6>}

                                <button
                                    type="submit"
                                    onClick={submitChooseLesson}
                                    className="btn btn-main btn-block"
                                >
                                    Үргэлжлүүлэх
                                </button>
                            </div>
                        )}
                        {
                            // step == Steps[2] &&
                            // <div>
                            //     <SelectedPackageItem />
                            //     {
                            //         (PACKAGES_DATA[2].type != packageType) &&
                            //         <div>
                            //             <h5>Хичээл</h5>
                            //             <ul className="select-pack list-unstyled">
                            //                 {
                            //                     coursesData
                            //                         .filter(({ docId }) => (selectedCourses.includes(docId)))
                            //                         .map(course => (
                            //                             <li>
                            //                                 <div className="form-check">
                            //                                     <input className="form-check-input" type="checkbox" name="exampleRadios" id={"exampleRadio" + course.docId} checked={false} />
                            //                                     <label className="form-check-label" style={{ paddingBottom: '0.4rem', paddingTop: '0.4rem' }}>
                            //                                         <p className="payment-info d-flex align-items-center flex-wrap">
                            //                                             <strong>{course.name}</strong>
                            //                                         </p>
                            //                                     </label>
                            //                                 </div>
                            //                             </li>
                            //                         ))
                            //                 }
                            //             </ul>
                            //         </div>
                            //     }
                            //     <h5>Төлбөрийн хэлбэр</h5>
                            //     <div>
                            //         <ul className="payments list-unstyled d-flex align-items-center flex-wrap justify-content-center">
                            //             {/* <li className="" onClick={() => { setPaymentType(PaymentType[0]) }}>
                            //                 <div className="form-check">
                            //                     <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked={PaymentType[0] == paymentType} />
                            //                     <label className="form-check-label" for="exampleRadios1">
                            //                         <img src={khanLogo} alt="" />
                            //                     </label>
                            //                 </div>
                            //             </li> */}
                            //             <li className="" onClick={() => { setPaymentType(PaymentType[1]) }} style={{ width: '200px' }}>
                            //                 <div className="form-check">
                            //                     <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked={PaymentType[1] == paymentType} />
                            //                     <label className="form-check-label" for="exampleRadios2">
                            //                         <img src={qpayLogo} alt="" />
                            //                     </label>
                            //                 </div>
                            //             </li>
                            //             {/* <li className="" onClick={() => { setPaymentType(PaymentType[2]) }}>
                            //                 <div className="form-check">
                            //                     <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option4" checked={PaymentType[2] == paymentType} />
                            //                     <label className="form-check-label" for="exampleRadios4">
                            //                         <img src={socialLogo} alt="" />
                            //                     </label>
                            //                 </div>
                            //             </li> */}
                            //         </ul>
                            //         <button className="btn btn-main btn-block" onClick={submit}>Төлбөр төлөх</button>
                            //     </div>
                            // </div>
                        }
                        {step == Steps[3] && (
                            <div>
                                <SelectedPackageItem />
                                {PACKAGES_DATA[2].type != packageType && (
                                    <div>
                                        <h5 style={{ marginTop: "-20px" }}>
                                            Хичээл
                                        </h5>
                                        <ul className="select-pack list-unstyled">
                                            {coursesData
                                                .filter(({ docId }) =>
                                                    selectedCourses.includes(
                                                        docId
                                                    )
                                                )
                                                .map((course) => (
                                                    <li>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="exampleRadios"
                                                                id={
                                                                    "exampleRadio" +
                                                                    course.docId
                                                                }
                                                                checked={false}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                style={{
                                                                    paddingBottom:
                                                                        "0.4rem",
                                                                    paddingTop:
                                                                        "0.4rem",
                                                                }}
                                                            >
                                                                <p className="payment-info d-flex align-items-center flex-wrap">
                                                                    <strong>
                                                                        {
                                                                            course.name
                                                                        }
                                                                    </strong>
                                                                </p>
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                                {invoice && (
                                    <div className="d-flex justify-content-center">
                                        <h5
                                            onClick={() => {
                                                setShowCoupon(!showCoupon);
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                marginBottom: "0px",
                                                marginTop: "0px",
                                                color: "orange",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            Купон ашиглах
                                        </h5>
                                    </div>
                                )}
                                {showCoupon && (
                                    <div style={{ marginBottom: "20px" }}>
                                        <input
                                            className="couponInput"
                                            placeholder="Купоны код оруулах"
                                            value={couponId}
                                            onChange={(e) => {
                                                setCouponId(e.target.value);
                                            }}
                                        />

                                        {couponError && <h5>{couponError}</h5>}

                                        <button
                                            onClick={submitCoupon}
                                            className="btn btn-main btn-block"
                                        >
                                            {loadingCoupon
                                                ? "Уншиж байна"
                                                : "Ашиглах"}
                                        </button>
                                    </div>
                                )}
                                {loading && !showCoupon && (
                                    <h4
                                        className="modal-title text-center"
                                        id="exampleModalLabel"
                                    >
                                        <Lottie
                                            options={defaultOptions}
                                            height={"200px"}
                                            width={"200px"}
                                            isStopped={false}
                                            isPaused={false}
                                        />
                                    </h4>
                                )}
                                {!loading && !showCoupon && <QPayItem />}
                                {invoice &&
                                    !showCoupon &&
                                    (isPaid ? (
                                        <InvoicePaid />
                                    ) : (
                                        <InvoiceStatus />
                                    ))}
                            </div>
                        )}
                        <p className="policy">
                            Та төлбөр төлснөөр манай үйлчилгээний нөхцөл{" "}
                            <a
                                onClick={() => {
                                    setModal("policy");
                                }}
                            >
                                ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ
                            </a>{" "}
                            - ийг хүлээн зөвшөөрсөнд тооцно.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
