import React, { useContext } from 'react'

import fbImage from './../images/9.png'
import gImage from './../images/8.png'
import { SignInSocial } from './signInSocial'
import { useFirebase } from '../firebase'
import { ModalContext } from '../providers/modal-provider'
import { Invoices } from './invoices'

const ProfileModal = ({ close }) => {
    const { setModal } = useContext(ModalContext)
    const { logout } = useFirebase()

    const disableClick = (e) => {
        e.stopPropagation();
    }

    const logoutAction = () => {
        setModal(false)
        logout()
    }

    return (
        <div className="modal log-reg" id="exampleModal" aria-labelledby="exampleModalLabel" onClick={close}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content" onClick={disableClick}>
                    <div className="modal-header">
                        <h4 className="modal-title text-center" id="exampleModalLabel">Profile</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                            <span aria-hidden="true">
                                <i className="lni lni-close"></i>
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={logoutAction}>
                            <span aria-hidden="true">
                                logout
                            </span>
                        </button>

                        <Invoices />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ProfileModal }