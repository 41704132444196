import React, { useEffect, useContext } from 'react';
import _ from 'lodash'
import { useParams } from 'react-router';
import { TeacherTop } from '../components/detail/teacherTop';
import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { Questions } from '../components/questions';
import { useDoc } from '../hooks/use-collection';
import { AuthContext } from '../providers/auth-user-provider';
import { FirebaseContext } from '../providers/firebase-collection';

export const Course = () => {
    const { courseId } = useParams()
    const { ready, user } = useContext(AuthContext);
    const { chaptersData, loadingChapters, setLoad, lessonsData, loadingLessons } = useContext(FirebaseContext);
    const { data: course } = useDoc(`courses/${courseId}`)

    useEffect(() => {
        setLoad({ chapter: { id: courseId }, lessons: { courseId } })

        return () => {
            setLoad({ chapter: false, lessons: false })
        }
    }, [courseId])

    const Lesson = ({ name, chapterId, id }, index) => {
        return (
            <div key={index}>
                <span>{chapterId} - {id} - {name}</span>
            </div>
        )
    }

    const Lessons = () => {
        return (
            <div>
                {
                    loadingLessons ?
                        <span>loading</span>
                        :
                        _.chain(lessonsData)
                            .sortBy([
                                (obj) => parseInt(obj.chapterId, 10),
                                (obj) => parseInt(obj.id, 10),
                            ])
                            .value()
                            .map(Lesson)
                }
            </div>
        )
    }


    return (
        <div className="wrap">
            <Header />
            <TeacherTop {...course} />
            <Lessons />
            <Questions />
            <Footer />
        </div >
    )
}