import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { Footer } from '../components/footer'
import { Header } from '../components/header'
import { Invoices } from '../components/invoices'
import { MyCourses } from '../components/profile/myCourses'
import { Questions } from '../components/questions'
import { useFirebase } from '../firebase'
import { AuthContext } from '../providers/auth-user-provider'


export const Profile = () => {
    const { ready, user } = useContext(AuthContext);
    const { logout } = useFirebase()
    const history = useHistory()

    const { uid, displayName = null, email = null, photoURL = null, metadata: { creationTime = null } } = user || { metadata: {} }

    const ProfileTop = () => {
        return (
            <ul className="list-unstyled d-flex align-items-center justify-content-between">
                <li>
                    <a className="btn" onClick={() => { history.goBack() }}>
                        <i className="lni lni-arrow-left"></i>
                        <span>Буцах</span>
                    </a>
                </li>
                <li>
                    <a className="btn btn-line" onClick={logout}>
                        <i className="lni lni-exit"></i>
                        <span>Гарах</span>
                    </a>
                </li>
            </ul>
        )
    }

    const ProfileItem = () => {
        return (
            <div className="row">
                <div className="offset-lg-2 col-lg-8">
                    <div className="user-info text-center">
                        <span className="rimg r11" style={{ backgroundImage: `url(${photoURL}?type=large)` }} ></span>
                        <div className="info">
                            <p className="name">{displayName}</p>
                            <p className="phone">{email}</p>
                            <p className="phone" style={{ fontSize: '16px', color: 'gray', marginTop: '10px' }}>{uid}</p>
                            <p className="date">Бүртгүүлсэн: {creationTime && (new Date(creationTime)).toISOString().substring(0, 10)}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="wrap">
            <Header />
            <section className="user-profile">
                <div className="container">
                    <ProfileTop />
                    <ProfileItem />
                    <MyCourses />
                    <Invoices />
                </div>
            </section>
            <Questions />
            <Footer />
        </div>
    )
}
