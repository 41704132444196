import React, { useContext, useState } from "react";
import { useFirebase } from "../../firebase";
import { ModalContext } from "../../providers/modal-provider";

export const TeacherTop = ({
    paid,
    myCourse,
    docId,
    user,
    image = "",
    image_2 = "",
    teacher = { name: "" },
    name = "",
    setIsPayment,
}) => {
    const { setModal } = useContext(ModalContext);
    const { functions } = useFirebase();
    const [loading, setLoading] = useState(false);

    const StartButton = () => {
        if (docId === "xb8iyAXYK47S6xf8Nj4x" || (user && user.premium)) {
            var startCourse = functions.httpsCallable("startCourse");
            startCourse({
                courseId: docId,
            })
                .then((result) => {
                    window.location.reload(false);
                })
                .catch((error) => {
                    alert(error.message);
                    setLoading(false);
                });
        } else {
            setModal("payment");
        }
    };

    return (
        <section className="teacher-big-top minHeigh">
            <img src={image} className="img-fluid d-lg-block d-none" alt="" />
            <img src={image} className="img-fluid d-lg-none d-block" alt="" />
            <div className="absolute-container">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <h1>
                                <strong>{teacher.name}</strong>
                            </h1>
                            <h5>{teacher.title2}</h5>
                            {paid === false && (
                                <div className="btn-stack">
                                    <a
                                        className="btn btn-main btn-block"
                                        onClick={StartButton}
                                    >
                                        {" "}
                                        Хичээл эхлүүлж үзэх{" "}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
