import Player from '@vimeo/player';
import React, { useEffect, useRef, useState } from 'react';
import { useFirebase } from '../../firebase';

export const TrailerIntro = ({ courseId, myCourse, paid, user, course, lessons, loadingLessons }) => {
    const { firestore } = useFirebase();
    const playerRef = useRef(null)

    const [activeInx, setActiveInx] = useState(-1)
    const [videoId, setVideoId] = useState(null)
    const [playerTag, setPlayer] = useState(null)
    const [isEnded, setIsEnded] = useState(false)
    const [showNextVideo, setShowNextVideo] = useState(false)
    const [_, setCompleted] = useState(false)
    const [count, setCount] = useState(0)
    const [watchedList, setWatchedList] = useState([])

    useEffect(() => {
        if (paid) {
        }
    }, [paid])

    useEffect(() => {
        if (firestore && user && user.uid && courseId) {
            const unsubscribe = firestore.collection(`users/${user.uid}/courses/${courseId}/watched`)
                .onSnapshot((s) => {
                    const list = s.docs.map((doc) => doc.id)
                    setWatchedList(list);
                })

            return () => unsubscribe();
        }
    }, [firestore, user, courseId])

    useEffect(() => {
        if (myCourse && lessons.length) {
            const lastWatchedLesson = myCourse?.lastViewed?.lesson

            if (lastWatchedLesson) {
                const watched = lessons
                    .map((value, index) => ({ ...value, index }))
                    .find(({ docId }) => ('' + docId) == ('' + lastWatchedLesson))

                if (watched) {
                    setActiveInx(watched.index)
                } else {
                    setActiveInx(0)
                }

            } else {
                setActiveInx(0)
            }
        }
    }, [myCourse, lessons])

    useEffect(() => {
        if (paid) {
            let { video } = lessons[activeInx] || { video: "" }
            if (video == 'uuid-1234') {
                video = ""
            }

            setIsEnded(false)
            setShowNextVideo(false)
            setVideoId(video)
        }
    }, [activeInx, paid])

    useEffect(() => {
        setIsEnded(false)
        setShowNextVideo(false)

        if (!playerRef && !playerRef.current) {
            setCount(++count)
        }

        if (playerRef && playerRef.current && videoId) {
            if (playerTag) {
                playerTag.destroy()
            }

            const player = new Player(playerRef.current, {
                id: videoId,
                autoplay: true
            });

            const duration = player.getDuration()

            player.on('ended', function () {
                setIsEnded(true)
                player.destroy()
                watchedLesson()
            })

            setPlayer(player)
        }
    }, [playerRef, videoId, count])

    const watchedLesson = () => {
        firestore.doc(`users/${user.uid}/courses/${courseId}/watched/${lessons[activeInx].docId}`)
            .set({
                createdAt: new Date(),
                updatedAt: new Date(),
            })

        if (activeInx + 1 < lessons.length) {
            firestore.doc(`users/${user.uid}/courses/${courseId}`)
                .update({
                    lastViewed: {
                        date: new Date(),
                        lesson: lessons[activeInx + 1].docId,
                        time: 0
                    }
                })
        } else {
            firestore.doc(`users/${user.uid}/courses/${courseId}`)
                .update({
                    isCompleted: true,
                    completedAt: new Date()
                })
        }
    }

    const Lesson = ({ docId, video = '', name, minutes = 0, index }) => {
        const playColor = "#676767"
        const isPlaying = activeInx == index
        const isWatched = watchedList.includes("" + docId)

        return (
            <li>
                <a className="content" onClick={() => {
                    if (paid && video) {
                        setActiveInx(isPlaying ? -1 : index);
                    }
                }} style={isPlaying ? { backgroundColor: playColor } : {}}>
                    <p className="title">
                        <span>{index + 1}. {name}</span>
                        {
                            (paid == false || (video == false || video == '')) ?
                                < i className="lni lni-lock-alt" style={{ fontSize: '20px' }}></i>
                                :
                                isPlaying ?
                                    < i className="lni lni-play" style={{ fontSize: '20px' }}></i>
                                    :
                                    isWatched &&
                                    <i className="lni lni-checkmark-circle" style={{ fontSize: '20px' }}></i>
                        }
                    </p>
                    {
                        (video == false || video == '') &&
                        <div className="pb-3 d-flex align-items-center" style={{ color: 'rgb(48, 125, 246)' }}> <i className="lni lni-pencil pr-2" style={{ fontSize: '20px' }}></i> Бэлтгэгдэж буй </div>
                    }
                </a>
            </li >
        )
    }

    const selectNext = () => {
        if (activeInx + 1 == lessons.length) {
            setCompleted(true)
        } else {
            setIsEnded(false)
            setActiveInx(activeInx + 1)
        }
    }

    const takeTest = () => {
        window.open(
            lessons[activeInx].testUrl,
            '_blank'
        );

        setTimeout(() => {
            setShowNextVideo(true);
        }, 2000)
    }

    const startFirstVideo = () => {
        setVideoId(course.video_trailer)
    }

    return (
        <section className="trailer-intro-container py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 order-lg-1">
                        <div className="video-intro" style={{ backgroundColor: `black` }}>
                            {
                                (isEnded && lessons && lessons[activeInx]) ?
                                    <span className="rimg embed-container" style={{ backgroundColor: `rgb(19, 19, 19)`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="align-middle embed-container" style={{ backgroundColor: `#131313`, justifyContent: 'center', alignItems: 'center' }}>
                                            <h4 style={{ color: '#F1F1F1', fontSize: '18px', textAlign: 'center' }}>{lessons[activeInx].id}. {lessons[activeInx].name}</h4>
                                            <h5 style={{ color: '#F1F1F1', fontSize: '18px', textAlign: 'center' }}>Доорх холбоосоор орж хичээлээ бататгах шалгалт өгөөрэй.</h5>
                                            <div className="btn-stack" style={{ display: 'flex', justifyContent: 'center' }}>
                                                {
                                                    (showNextVideo || !lessons[activeInx]?.testUrl) ?
                                                        <a className="btn btn-main btn-block" style={{ width: '150px' }} target="_blank" onClick={selectNext}> Дараагийн хичээл </a>
                                                        :
                                                        <a className="btn btn-main btn-block" style={{ width: '150px' }} onClick={takeTest} > Шалгалт өгөх </a>
                                                }
                                            </div>
                                        </div>
                                    </span>
                                    :
                                    <span className="rimg embed-container " ref={playerRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${course?.image_2})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', cursor: 'pointer' }}>
                                        {
                                            activeInx == -1 &&
                                            <span onClick={startFirstVideo} style={{ backgroundColor: 'rgba(0,0,0,0.6)', height: '70px', width: '70px', borderRadius: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <svg style={{ marginLeft: '5px' }} fill="#f1f1f1" height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><path class="ytp-svg-fill" d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z" id="ytp-id-47"></path></svg>
                                            </span>
                                        }
                                    </span>
                            }
                        </div>
                    </div>
                    <div className="col-lg-8 order-lg-3">
                        <h4>{course?.teacher?.title}</h4>
                        <div className="video-intro-text">
                            {
                                course?.teacher &&
                                <h5 style={{ marginTop: '20px', fontSize: '1rem' }}>Багш: {course?.teacher?.name}</h5>
                            }
                            <p className="text">{course?.teacher?.description}</p>
                            {
                                course?.teacher?.avgScore &&
                                <h5 style={{ marginTop: '20px', fontSize: '1rem' }}>Жил бүрийн ЭЕШ-ийн дундаж оноо: {course?.teacher?.avgScore}</h5>
                            }
                            {
                                course?.teacher?.startTime &&
                                <h5 style={{ marginTop: '20px', fontSize: '1rem' }}>Ажилласан жил: {(new Date()).getFullYear() - course?.teacher?.startTime}</h5>
                            }
                            {
                                course?.teacher?.award &&
                                <h5>Шагнал:</h5>
                            }
                            {

                                course?.teacher?.award &&
                                <ul style={{ maxWidth: '100%', color: "rgba(255, 255, 255, 0.7)", fontSize: '0.9rem' }} >
                                    {
                                        course?.teacher?.award.map((item) => (
                                            <li>
                                                <span>
                                                    {item}
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                            {
                                course?.teacher?.success &&
                                <h5>Амжилт:</h5>
                            }
                            {

                                course?.teacher?.success &&
                                <ul style={{ maxWidth: '100%', color: "rgba(255, 255, 255, 0.7)", fontSize: '0.9rem' }} >
                                    {
                                        course?.teacher?.success.map((item) => (
                                            <li>
                                                <span>
                                                    {item}
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                            {
                                course?.teacher?.experience &&
                                <h5>Ажлын туршлага: </h5>
                            }
                            {
                                course?.teacher?.experience &&
                                <ul style={{ maxWidth: '100%', color: "rgba(255, 255, 255, 0.7)", fontSize: '0.9rem' }} >
                                    {
                                        course?.teacher?.experience.map((item) => (
                                            <li>
                                                <span>
                                                    {item}
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 order-lg-2 d-flex flex-column">
                        {
                            course?.video_trailer &&
                            <a onClick={() => { setVideoId(course.video_trailer) }} className="btn btn-play btn-block">
                                <i className="lni lni-play"></i>
                                <span>Видео танилцуулга</span>
                            </a>
                        }
                        {
                            course?.video_sample &&
                            <a onClick={() => { setVideoId(course.video_sample) }} className="btn btn-play btn-block">
                                <i className="lni lni-play"></i>
                                <span>Үлгэрчилсэн видео хичээл</span>
                            </a>
                        }
                        <p className="title mt-4 mb-2">Сургалтын төлөвлөгөө - {lessons.length} хичээл </p>
                        <div className="lesson-plan d-flex flex-column flex-grow-1">
                            <ul className="list-unstyled">
                                {
                                    lessons &&
                                    lessons.map((lesson, index) => (<Lesson {...lesson} index={index} />))
                                }
                            </ul>
                        </div>
                    </div>
                </div >
                <hr />
            </div>
        </section >
    )
}

