import firebase from 'firebase/app';
import { useFirebase } from '../firebase';
import { useCollection } from '../hooks/use-collection';


export const SignInSocial = () => {
    const { auth } = useFirebase()
    const { updateDoc } = useCollection({ path: `/users-data` })

    var facebookProvider = new firebase.auth.FacebookAuthProvider();

    const signInWithFacebook = (success) => {
        auth.signInWithPopup(facebookProvider).then(async (result) => {
            // const { profile } = result.additionalUserInfo;

            // const email = profile.email
            // const firstName = profile.first_name
            // const lastName = profile.last_name
            // const name = profile.name
            // const photo = profile.picture.data.url

            // const { uid, providerData } = result.user

            // await updateDoc(uid, {
            //     result,
            // })

            // const providers = {}
            // providerData.map(item => { providers[[item.providerId]] = item.uid })

            // await updateDoc(uid, {
            //     createdAt: new Date(),
            //     name,
            //     email,
            //     firstName,
            //     lastName,
            //     photo,
            //     ...providers
            // })

            // auth.currentUser.updateProfile({
            //     displayName: name
            // }).then(function (res) {
            //     console.log(res)
            // }).catch(function (error) {
            //     console.log(error)
            // });

            success()
        }).catch(async function (error) {
            console.log(error)
        });
    }

    const googleProvider = new firebase.auth.GoogleAuthProvider()

    const signInWithGoogle = (success) => {
        auth.signInWithPopup(googleProvider).then(async (res) => {
            // const { user } = res
            // const {
            //     email,
            //     displayName: name,
            //     photoURL: photo,
            //     phoneNumber,
            //     uid,
            // } = user

            // await updateDoc(uid, {
            //     res,
            // })

            // const providers = {}
            // user.providerData.map(item => { providers[[item.providerId]] = item.uid })

            // await updateDoc(uid, {
            //     createdAt: new Date(),
            //     email,
            //     name,
            //     photo,
            //     phoneNumber,
            //     ...providers
            // })

            // auth.currentUser.updateProfile({
            //     displayName: name
            // }).then(function (res) {
            //     console.log(res)
            // }).catch(function (error) {
            //     console.log(error)
            // });

            success()
        }).catch(async (error) => {
            console.log(error)
        })
    }

    return { signInWithFacebook, signInWithGoogle }
}