import React from 'react';

const PolicyModal = ({ close }) => {
    const disableClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="modal log-reg" id="exampleModal" aria-labelledby="exampleModalLabel" onClick={close}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content" onClick={disableClick} style={{ maxHeight: '80vh' }}>
                    <div className="modal-header">
                        <h4 className="modal-title text-center" id="exampleModalLabel">ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
                            <span aria-hidden="true">
                                <i className="lni lni-close"></i>
                            </span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ overflow: 'scroll' }}>
                        <h5>1. ЕРӨНХИЙ ЗҮЙЛ</h5>
                        <p>1.1. Upgrade.mn /вэб хуудас/ нь онлайн хичээлүүдийг нэгтгэсэн вэб хуудас болно.</p>
                        <p>1.2. Тус вэб хуудсанд байршсан хичээлүүд нь Оюуны өмчий тухай хууль, Зохиогчийн эрх болон түүнд хамаарах эрхийн тухай хуульд заасны дагуу Upgrade.mn-ийн өмч байна.</p>
                        <p>1.3. Upgrade.mn-ийн төлбөрийн нөхцөлийг хүлээн зөвшөөрч, төлбөрөө төлсөн тохиолдолд вэб хуудсанд байршсан хичээлүүдийг судлах эрх үүснэ.</p>
                        <h5>2. ГИШҮҮНЧЛЭЛ</h5>
                        <p>2.1. Гишүүнчлэлд нэгдэх хүсэлтэй хэрэглэгч өөрийн Facebook хуудас, эсхүл Gmail-ээр нэвтэрнэ</p>
                        <p>2.2. Вэб хуудасны үйлчилгээний нөхцөлийг хүлээн зөвшөөрч, шаардлагатай мэдээллүүдийг үнэн зөв бөглөж, төлбөрийн нөхцөлийг хүлээн зөвшөөрч, нэхэмжилсэн төлбөрийг бүрэн төлснөөр гишүүнчлэх эрх нээгдэнэ /цаашид “гишүүнчлэлд нэгдсэн хэрэглэгч” гэнэ./</p>
                        <p>2.3. Гишүүнчлэлд нэгдсэн хэрэглэгч мэдээллээ үнэн зөв бөглөх ба, нууцыг чанадлан хадгалах үүргийг вэб хуудас хариуцна.</p>
                        <p>2.4. Гишүүнчлэлд нэгдсэн хэрэглэгч өөрийн эрхээ бусдад шилжүүлэх, худалдахыг хориглоно. Эсвээс үүссэн хохирлыг хэрэглэгч өөрөө бүрэн хариуцна.</p>
                        <p>2.5. Гишүүнчлэлд нэгдсэн хэрэглэгч вэб хуудсыг ашиглахад зайлшгүй шаардагдах тоног төхөөрөмж, интернетийг өөрөө бүрэн хариуцна.</p>
                        <p>2.6. Гишүүнчлэлд нэгдсэн хэрэглэгч худалдаж авсан контентоо цорын ганц гар утас, компьютер, таблетаар тоглуулах боломжтой ба өөр хэрэгслээр тоглуулах боломжгүй.</p>
                        <h5>3. ВЭБ ХУУДАС</h5>
                        <p>3.1. Дэвшилтэт техник, технологийг ашиглан бүтээсэн контентоо вэб хуудсандаа байршуулж, гишүүнчлэлд нэгдсэн хэрэглэгчид мэдээлэх үүргийг вэб хуудас хариуцна.</p>
                        <p>3.2. Гишүүнчлэлд нэгдсэн хэрэглэгчийн эрх ашгийг хамгаалах, мэдээллийн шинэчлэл бүрийг тухай бүрд мэдэгдэх үүргийг вэб хуудас хариуцна.</p>
                        <p>3.3. Гишүүнчлэлд нэгдсэн хэрэглэгчийн санал гомдлыг сонсох, хэлэлцэх, шийдвэрлэх үүрэг нь вэб хуудсын үндсэн бодлого байна.</p>
                        <p>3.4. Вэб хуудасны алдаатай бодлогын улмаас гишүүнчлэлд нэгдсэн хэрэглэгчид хохирол үүсвэл вэб хуудас хохирлыг барагдуулна.</p>
                        <p>3.5. Давагдашгүй хүчин зүйлийн улмаас үүссэн эрсдэлийг гишүүнчлэлд нэгдсэн хэрэглэгчдэд ил тод тайлагнах ч, хэрэглэгчид учирсан хохирлыг барагдуулах нөхцөл үүсэхгүй.</p>
                        <h5>4. ТӨЛБӨР ТООЦОО</h5>
                        <p>4.1. Гишүүнчлэлд нэгдсэн хэрэглэгч зөвхөн QPAY ашиглан төлбөр төлнө.</p>
                        <p>4.2. Вэб хуудасны алдаатай бодлогын улмаас үүссэн эрсдэлээс бусад тохиолдолд төлбөрийг буцаан олгох нөхцөл үүсэхгүй.</p>
                        <p>4.3. Гишүүнчлэлд нэгдсэн хэрэглэгч төлбөрийг бүрэн төлсний дараа контентийг нэг хичээлийн жилийн турш буюу арван сарын турш эзэмших эрхийг эдэлнэ.</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export { PolicyModal };

