import React from "react";

const statisticsData = [
    {
        h2: "380+ цаг",
        h5: "Нийт хичээлийн цаг",
    },
    {
        h2: "8 хичээл",
        h5: "сургалт явуулдаг",
    },
    {
        h2: "20 минут",
        h5: "илүүгүй хугацаатай",
    },
];

const Statistics = () => {
    const StatisticsBlock = (props) => {
        const { h2, h5 } = props;

        return (
            <div className="col-lg-4">
                <div className="single">
                    <h2>{h2}</h2>
                    <h5>{h5}</h5>
                </div>
            </div>
        );
    };

    return (
        <section className="teacher-slide" style={{ padding: 0 }}>
            <div className="container">
                <div className="triple-section">
                    <div className="row">
                        {statisticsData.map((el, ind) => (
                            <StatisticsBlock
                                key={`block-${ind}`}
                                h2={el.h2}
                                h5={el.h5}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export { Statistics };
