import React, { useState } from 'react'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { useCollection, useDoc } from '../../hooks/use-collection'
import './admin.scss'

export const InvoiceAdmin = () => {
    const [userId, setUserId] = useState('')
    const [userIdValue, setUserIdValue] = useState('')
    const [activeId, setActiveId] = useState(false)

    const { data, updateDoc } = useCollection({
        path: 'invoices',
        load: true,
        orders: ['-createdAt'],
        limit: 10,
        userId: userIdValue
    })

    const search = () => {
        setUserIdValue(userId)
    }

    const confirm = (invoiceId) => {
        updateDoc(invoiceId, { status: 'complete', paidBy: 'admin' })
    }

    const Item = ({
        docId,
        createdAt,
        amount,
        packageType,
        status,
        userId,
    }) => {
        let color = 'white'
        if (status == 'complete') {
            color = 'green'
        } else if (status == 'pending') {
            color = 'yellow'
        }

        return (
            <tr>
                <td>{createdAt ? new Date(createdAt.seconds * 1000).toISOString().substr(0, 19) : ''}</td>
                <td>{userId}</td>
                <td>{docId}</td>
                <td style={{ color }}>{status}</td>
                <td>{packageType}</td>
                {
                    (status == 'pending' && activeId === docId) &&
                    <td onClick={() => { confirm(docId) }}>
                        Are you sure to confirm
                    </td>
                }
                {
                    (status == 'pending' && activeId !== docId) &&
                    <td onClick={() => { setActiveId(docId) }}>
                        Confirm
                    </td>
                }
                {
                    (status != 'pending') &&
                    <td />
                }
            </tr>
        )
    }

    return (
        <div className="right">
            <div style={{ padding: '20px', paddingLeft: '0px' }}>
                <input value={userId} onChange={(e) => { setUserId(e.target.value) }} style={{ width: '300px' }} placeholder="userId" />
                <span onClick={search} style={{ marginLeft: '10px' }}> Search </span>
            </div>

            <table id="customers">
                <tr>
                    <th>Created at</th>
                    <th>UserId</th>
                    <th>InvoiceId</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Action</th>
                </tr>
                {
                    data.map(Item)
                }
            </table>
        </div>
    )
}

export const UsersAdmin = ({ }) => {
    const [email, setEmail] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    const [name, setName] = useState('')
    const [nameValue, setNameValue] = useState('')
    const [uid, setUID] = useState('')
    const [uidValue, setUidValue] = useState('')

    const { data } = useCollection({
        path: 'users-data',
        orders: ['name'],
        load: true,
        limit: 10,
        email: searchEmail,
        name: nameValue,
    })

    const { data: user } = useDoc(`users-data/${uid}`)

    const searchUid = () => {
        setUidValue(uid)
        setName('')
        setNameValue('')
        setEmail('')
        setSearchEmail('')
    }

    const search = () => {
        setSearchEmail(email)
        setName('')
        setNameValue('')
        setUidValue('')
        setUID('')
    }

    const searchName = () => {
        setEmail('')
        setSearchEmail('')
        setNameValue(name)
        setUidValue('')
        setUID('')
    }

    const Item = ({
        docId,
        createdAt,
        name,
        status,
        email,
        premiumExpiredAt,
    }) => {
        return (
            <tr>
                <td>{docId}</td>
                <td>{createdAt ? new Date(createdAt.seconds * 1000).toISOString().substr(0, 19) : ''}</td>
                <td>{name}</td>
                <td>{premiumExpiredAt ? new Date(premiumExpiredAt.seconds * 1000).toISOString().substr(0, 19) : ''}</td>
                <td>{email}</td>
            </tr>
        )
    }

    return (
        <div className="right">
            <div style={{ padding: '20px', paddingLeft: '0px' }}>
                <input value={email} onChange={(e) => { setEmail(e.target.value) }} style={{ width: '300px' }} placeholder="Email" />
                <span onClick={search} style={{ marginLeft: '10px' }}> Search by email </span>

                <br />

                <input value={name} onChange={(e) => { setName(e.target.value) }} style={{ width: '300px', marginTop: '20px' }} placeholder="Name" />
                <span onClick={searchName} style={{ marginLeft: '10px' }}> Search by name </span>

                <br />

                <input value={uid} onChange={(e) => { setUID(e.target.value) }} style={{ width: '300px', marginTop: '20px' }} placeholder="UID" />
                <span onClick={searchUid} style={{ marginLeft: '10px' }}> Search by UID </span>
            </div>

            <table id="customers">
                <tr>
                    <th>UID</th>
                    <th>Created at</th>
                    <th>Name</th>
                    <th>Premium expired at</th>
                    <th>Email</th>
                </tr>
                {
                    !uid &&
                    data.map(Item)
                }
                {
                    uid &&
                    <Item {...user} />
                }
            </table>
        </div>
    )
}

export const Admin = ({ }) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [menu, setMenu] = useState('users')

    return (
        <div className="wrap admin">
            <Header />
            <div className="body">
                <div className="left">
                    <div className="left-item">
                        Нүүр хуудас
                    </div>
                    <div className={`left-item ${menu == 'users' && 'active'}`} onClick={() => { setMenu('users') }}>
                        Хэрэглэгч
                    </div>
                    <div className={`left-item ${menu == 'invoices' && 'active'}`} onClick={() => { setMenu('invoices') }}>
                        Нэхэмжлэл
                    </div>
                </div>

                {
                    menu == 'users' &&
                    <UsersAdmin />
                }
                {
                    menu == 'invoices' &&
                    <InvoiceAdmin />
                }
            </div>

            <Footer />
        </div >
    )
}