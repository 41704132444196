import React, { createContext, useEffect, useState } from 'react';
import { useFirebase } from '../firebase';

export const AuthContext = createContext({
    user: null,
    ready: false
});

export const AuthUserProvider = ({ children }) => {
    let [state, setState] = useState({
        ready: false,
        user: null,
    });
    let { auth } = useFirebase();

    useEffect(() => {
        if (!auth) {
            return;
        }
        const subscribe = auth.onAuthStateChanged(async authUser => {
            if (!authUser) {
                setState({ ready: true, user: null });
                return
            }

            let user = await authUser.getIdTokenResult().then((idTokenResult) => {
                let premium = false
                if (idTokenResult.claims.premiumExpiredAt - (new Date()).getTime()) {
                    premium = true
                }

                return { ...authUser, premiumExpiredAt: idTokenResult.claims.premiumExpiredAt, admin: idTokenResult.claims.admin, premium }
            });

            setState({ ready: true, user })
        });

        return () => subscribe()
    }, [auth]);

    return (
        <AuthContext.Provider value={{ ...state }}>
            {children}
        </AuthContext.Provider>
    );
};
