import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../providers/auth-user-provider";
import { FirebaseContext } from "../providers/firebase-collection";
import { ModalContext } from "../providers/modal-provider";
import logo from "./../images/logo_black.png";
import { CouponModal } from "./coupon/couponModal";
import { PaymentModal } from "./payment/paymentModal";
import { PolicyModal } from "./policy/policyModal";
import { ProfileModal } from "./profileModal";
import { SigninModal } from "./signinModal";

const Header = () => {
    const history = useHistory();
    const { modal, setModal } = useContext(ModalContext);
    const [openSearch, setOpenSearch] = useState(false);
    const { user, ready } = useContext(AuthContext);
    const { coursesData, setLoad } = useContext(FirebaseContext);

    useEffect(() => {
        setLoad({ courses: true });
    }, []);

    const Teacher = ({ name, teacher, docId }) => {
        return (
            <div
                className="small-drop small-activated"
                style={{ height: "auto", position: "relative", padding: 0 }}
            >
                <a href={`/detail/${docId}`}>
                    <span
                        className="rimg r11"
                        style={{
                            backgroundImage: `url(${teacher.squareImage})`,
                        }}
                    ></span>
                    <div>
                        <span>{name}</span>
                        <span className="fs-rem-90 c-white8">
                            {teacher.name}
                        </span>
                    </div>
                </a>
            </div>
        );
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <a href="/" className="logo mr-auto mr-lg-4">
                            <img src={logo} alt="upgrade logo" />
                        </a>
                        <nav className="navbar navbar-expand mr-lg-auto">
                            <div
                                className="collapse navbar-collapse"
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item dropdown">
                                        <button
                                            className="btn flexer activate-cat nav-link"
                                            data-toggle="dropdown"
                                        >
                                            <span
                                                style={{ fontSize: "1.25rem" }}
                                            >
                                                Хичээлүүд
                                            </span>
                                            <i className="lni lni-chevron-down"></i>
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDropdown"
                                        >
                                            {coursesData &&
                                                coursesData.map((course) => (
                                                    <Teacher
                                                        key={course.docId}
                                                        {...course}
                                                    />
                                                ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        <a
                            className="btn"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/upgrade.edutech"
                        >
                            <i
                                style={{ fontSize: "1.25rem" }}
                                className="lni lni-facebook"
                            ></i>
                        </a>
                        <a
                            className="btn"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/upgrade.mn/"
                        >
                            <i
                                style={{ fontSize: "1.25rem" }}
                                className="lni lni-instagram"
                            ></i>
                        </a>

                        <button
                            className="btn"
                            onClick={() => {
                                if (user) {
                                    history.push("/profile");
                                } else {
                                    setModal("signin");
                                }
                            }}
                        >
                            {ready ? (
                                <div>
                                    {user ? (
                                        <span
                                            style={{
                                                fontSize: "1rem",
                                                marginLeft: "0.5rem",
                                            }}
                                            className="d-none d-md-inline"
                                        >
                                            {" "}
                                            {user.premium && (
                                                <span>Premium User /</span>
                                            )}{" "}
                                            {user.displayName}
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                fontSize: "1rem",
                                                marginLeft: "0.5rem",
                                            }}
                                            className="d-none d-md-inline"
                                        >
                                            Нэвтрэх
                                        </span>
                                    )}
                                    <i
                                        style={{ fontSize: "1rem" }}
                                        className="lni lni-user"
                                    ></i>
                                </div>
                            ) : null}
                        </button>

                        {!user && modal && (
                            <SigninModal
                                close={() => {
                                    setModal(false);
                                }}
                            />
                        )}

                        {user && modal == "profile" && (
                            <ProfileModal
                                close={() => {
                                    setModal(false);
                                }}
                            />
                        )}

                        {user && modal == "payment" && (
                            <PaymentModal
                                close={() => {
                                    setModal(false);
                                }}
                            />
                        )}

                        {user && modal == "coupon" && (
                            <CouponModal
                                close={() => {
                                    setModal(false);
                                }}
                            />
                        )}

                        {modal == "policy" && (
                            <PolicyModal
                                close={() => {
                                    setModal(false);
                                }}
                            />
                        )}
                    </div>
                </div>
            </header>
            {/* <div
                style={{
                    backgroundColor: "#FFFFFF",
                    marginBottom: 30,
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                    padding: "0.5px",
                }}
            > */}
            {/* <h2
                    style={{
                        color: "#000000",
                        margin: 0,
                        padding: "10px",
                        fontFamily: "Helvetica",
                    }}
                >
                    ШИНЭ СУРГАЛТ 12/02-НД ЭХЭЛНЭ.&nbsp;&nbsp;&nbsp;
                    <button
                        href="#"
                        className="btn btn-main btn-inline"
                        onClick={() => {
                            if (user) {
                                setModal("payment");
                            } else {
                                setModal("signin");
                            }
                        }}
                    >
                        Бүртгүүлэх
                    </button>
                </h2> */}
            {/* </div> */}
        </>
    );
};

export { Header };
