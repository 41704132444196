import React, { useContext, useEffect } from 'react'
import { FirebaseContext } from '../providers/firebase-collection'

export const Invoices = () => {
    const { invoicesData, coursesData, loadInvoices, setLoad } = useContext(FirebaseContext)

    useEffect(() => {
        setLoad({ invoices: true, courses: true })

        return () => {
            setLoad({ invoices: false })
        }
    }, [])

    const Invoice = (invoice) => {
        const courseInfos = coursesData.filter(course => invoice.courseIds.includes(course.docId))

        const courseNames = courseInfos.map(course => course.name).join(', ')
        const teachers = courseInfos.map(course => course.teacher.name).join(', ')
        const updated = (new Date(invoice.updatedAt.seconds * 1000)).toLocaleString()

        const translate = {
            'complete': 'Баталгаажсан',
            'pending': 'Хүлээгдэж байна',
        }

        return (
            <li>
                <a href="#" className="single-payment d-flex align-items-center justify-content-between" data-toggle="modal" data-target="#paymentHistoryModal">
                    <div className="info d-flex align-items-center">
                        <span className="rimg" style={{ backgroundImage: `url(${courseInfos.length ? courseInfos[0].image : '/favicon.png'})`, width: '80px', minWidth: '80px' }} ></span>
                        <div className="text">
                            {
                                invoice.packageType == 'all' ?
                                    <h5>{"Хамгийн хамгийн багц"}</h5>
                                    :
                                    <h5>{courseNames} - {teachers}</h5>
                            }
                            <p className="name">{translate[invoice.status]} </p>
                        </div>
                    </div>
                    <div className="price-info text-right">
                        <p className="price">{invoice.amount}₮</p>
                        <p className="date">{updated}</p>
                    </div>
                </a>
            </li>
        )
    }

    return (
        <div className="payment-history">
            <h2 className="text-center">Төлбөрийн түүх</h2>
            {
                (!loadInvoices && !invoicesData.length) &&
                <div className="row">
                    <div className="offset-lg-3 col-lg-6">
                        <div className="no-lesson">
                            <p>Худалдан авалт хийгээгүй байна</p>
                            <a href="#" className="btn btn-main" data-toggle="modal" data-target="#paymentModal">Хичээлийн багц авах</a>
                        </div>
                    </div>
                </div>
            }
            {
                invoicesData.length &&
                <div className="row">
                    <div className="offset-lg-3 col-lg-6">
                        <ul className="list-unstyled">
                            {
                                invoicesData.map(invoice => (<Invoice {...invoice} />))
                            }
                        </ul>
                    </div>
                </div>
            }
        </div >

    )
}
