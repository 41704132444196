import React from 'react';

import appStoreImage from './../images/Download_on_the_App_Store_Badge.svg';
import playStoreImage from './../images/Google_Play_Store_badge_EN.svg';

const Footer = () => {
    return (
        <footer style={{margin:0, padding: '0 0 2rem'}}>
            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-3 col-6">
                        <ul className="list-unstyled">
                            <li>
                                <strong>Explore</strong>
                            </li>
                            <li>
                                <a href="#">classes</a>
                            </li>
                            <li>
                                <a href="#">Articles</a>
                            </li>
                            <li>
                                <a href="#">Sitemap</a>
                            </li>
                            <li>
                                <a href="#">Gifts</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-6">
                        <ul className="list-unstyled">
                            <li>
                                <strong>About</strong>
                            </li>
                            <li>
                                <a href="#">Diversity, Equity, and Inclusion</a>
                            </li>
                            <li>
                                <a href="#">Careers</a>
                            </li>
                            <li>
                                <a href="#">Articles</a>
                            </li>
                            <li>
                                <a href="#">Sitemap</a>
                            </li>
                            <li>
                                <a href="#">Gifts</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul
                            className="list-unstyled social-links d-flex align-items-lg-start align-items-center flex-lg-column flex-row justify-content-center justify-content-lg-start">
                            <li>
                                <strong>Social</strong>
                            </li>
                            <li>
                                <a href="#"><i className="lni lni-facebook-filled"></i><span>Facebook</span></a>
                            </li>
                            <li>
                                <a href="#"><i className="lni lni-twitter-original"></i><span>Twitter</span></a>
                            </li>
                            <li>
                                <a href="#"><i className="lni lni-instagram-filled"></i><span>Instagram</span></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <ul
                            className="list-unstyled download d-flex align-items-center flex-lg-column flex-row justify-content-center justify-content-lg-start">
                            <li>
                                <strong>Download</strong>
                            </li>
                            <li>
                                <a href="#">
                                    <img src={appStoreImage} alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src={playStoreImage} alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className="bottom-text" style={{margin: '2rem 0 0 0'}}>
                    <ul className="list-unstyled d-flex align-items-center justify-content-center">
                        <li>© 2021 Upgrade.</li>
                        {/* <li>Secured with SSL</li> */}
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export { Footer }