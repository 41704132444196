import React from 'react'

import { MyCourses } from '../profile/myCourses'

export const MyCoursesPage = (props) => {
    const {user} = props
    return (
        <div>
        { user &&
            <section className="user-profile home-user-profile">
                <div className="container">
                    <MyCourses />
                </div>
            </section>
        }
        </div>
    )
}
