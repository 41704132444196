import React, { createContext, useContext, useState } from 'react';
import { useCollection } from '../hooks/use-collection';
import { AuthContext } from './auth-user-provider';

const initLoad = {
    courses: false,
    chapter: false,
    invoices: false,
    lessons: false,
    myCourse: false,
}

export const FirebaseContext = createContext({
    coursesData: [],
    loadingCourses: false,
    chaptersData: [],
    loadingChapters: false,
    load: initLoad,
    invoicesData: [],
    loadingInvoices: false,
    lessonsData: [],
    loadingLessons: false,
    myCoursesData: [],
    lessonDataDetail: [],
    loadingMyCourses: false,
    setLoad: () => { },
});

export const FirebaseProvider = ({ children }) => {
    const [load, setLoadFun] = useState(initLoad)
    const { user } = useContext(AuthContext)
    const { uid } = user || {}

    const { data: coursesData, loading: loadingCourses } = useCollection({ path: 'courses', load: load.courses })
    const { data: chaptersData, loading: loadingChapters } = useCollection({ path: `courses/${load.chapter.id}/chapters`, load: load.chapter, orders: ['id'] })
    const { data: invoicesData, loading: loadingInvoices } = useCollection({ path: `users/${uid}/invoices`, load: load.invoices, orders: ['createdAt'] })
    const { data: myCoursesData, loading: loadingMyCourses } = useCollection({ path: `users/${uid}/courses`, load: load.myCourses, orders: ['updatedAt'] })
    const { data: lessonsData, loading: loadingLessons } = useCollection({ path: `courses/${load.lessons.courseId}/lessons`, load: load.lessons?.courseId, orders: ['id'] })
    const { data: lessonDataDetail } = useCollection({ path: `courses/${load.lessons.courseId}/lessons-detail`, load: load.lessons?.courseId, orders: ['id'] })

    const setLoad = (state) => {
        setLoadFun(oldState => {
            return {
                ...oldState,
                ...state
            }
        })
    }

    return (
        <FirebaseContext.Provider value={{
            coursesData,
            loadingCourses,
            chaptersData,
            loadingChapters,
            invoicesData,
            loadingInvoices,
            lessonsData,
            loadingLessons,
            myCoursesData,
            lessonDataDetail,
            loadingMyCourses,
            load,
            setLoad,
        }}>
            {children}
        </FirebaseContext.Provider>
    );
};
