import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
};

export const useFirebase = () => {
    let [state, setState] = useState({ firebase });
    const history = useHistory()

    useEffect(() => {
        let app;
        if (!firebase.apps.length) {
            app = firebase.initializeApp(config);
        }
        let auth = firebase.auth(app);
        let firestore = firebase.firestore(app);
        let functions = firebase.functions(app);
        let analytics = firebase.analytics();

        const logout = () => {
            auth.signOut().then(function (res) {
                history.push('/')
                console.log(res)
            }).catch(function (error) {
                history.push('/')
                console.log(error)
            });
        }

        setState({ app, auth, firebase, firestore, functions, analytics, logout });
    }, []);

    return state;
};