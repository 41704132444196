import Player from "@vimeo/player";
import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper";

const slides = [
  // {
  //     name: 'Онцлох',
  //     coverImageUrl: '',
  //     videoId: '531132857'
  // },
  // {
  //     name: 'Мэдээ мэдээлэл',
  //     coverImageUrl: '',
  //     videoId: '531132857'
  // },
  {
    name: "Бидний тухай",
    coverImageUrl: "",
    videoId: "531132857",
  },
  {
    name: "Тусламж",
    coverImageUrl: "",
    videoId: "530791319",
  },
  // {
  //     name: 'FAQ',
  //     coverImageUrl: '',
  //     videoId: '531132857'
  // }
];

const PrettyCategories = () => {
  const categoryPlayerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAutoplay, setAutoplay] = useState(false);

  const [playerTag, setPlayer] = useState(null);

  const changeSlide = (newIndex) => {
    if (newIndex < 0) {
      newIndex = slides.length - 1;
    }
    if (newIndex === slides.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
    setAutoplay(true);
  };

  const SingleSlide = (props) => {
    const { index } = props;

    return (
      <div
        className={`swiper-slide ${
          activeIndex === index && "swiper-slide-thumb-active"
        }`}
      >
        <span onClick={() => changeSlide(index)}>{slides[index].name}</span>
      </div>
    );
  };

  useEffect(() => {
    if (categoryPlayerRef) {
      if (playerTag) {
        playerTag.destroy();
      }

      const player = new Player(categoryPlayerRef.current, {
        id: slides[activeIndex].videoId,
        autoplay: isAutoplay,
      });

      player.on("ended", function () {
        player.destroy();
      });
      setPlayer(player);
    }
  }, [categoryPlayerRef, activeIndex]);

  const swiper = new Swiper("swiper-category", {});

  return (
    <section className="our-categories">
      <div className="container">
        {/* <h2 className="text-center">Our class categories</h2> */}
        <div className="slide-wrap">
          <div className="swiper-container swiper-category">
            <div
              className="swiper-wrapper carousel-inner"
              style={{ justifyContent: "center" }}
            >
              {slides.map((_el, ind) => (
                <SingleSlide key={`singleSlide-${ind}`} index={ind} />
              ))}
            </div>
            {/* <span className="right-gradient"></span> */}
          </div>
          <div
            className="swiper-button swiper-button-next category-next"
            onClick={() => changeSlide(activeIndex + 1)}
          ></div>
          <div
            className="swiper-button swiper-button-prev category-prev"
            onClick={() => changeSlide(activeIndex - 1)}
          ></div>
        </div>
        <div className="slide-wrap this-full">
          <div className="swiper-container swiper-category-content">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <span
                  className="rimg embed-container"
                  ref={categoryPlayerRef}
                  style={{
                    backgroundColor: `#131313`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { PrettyCategories };
