import React, { useContext } from 'react';
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import './css/upgrade.css';
import './css/wrap.css';
import { Admin, Courses, HomeDefault } from './pages';
import { Course } from './pages/course';
import { Detail } from './pages/detail';
import { Profile } from './pages/profile';
import { AuthContext, AuthUserProvider } from './providers/auth-user-provider';
import { FirebaseProvider } from './providers/firebase-collection';
import { ModalProvider } from './providers/modal-provider';
import './scss/wrap.scss';

const Body = () => {
    const { user } = useContext(AuthContext)

    console.log(user)

    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <HomeDefault />
                </Route>
                <Route path="/detail/:courseId" exact>
                    <Detail />
                </Route>
                <Route path="/course/:courseId" exact>
                    <Course />
                </Route>
                <Route path="/cources" exact>
                    <Courses />
                </Route>
                <Route path="/profile" exact>
                    <Profile />
                </Route>
                {
                    (user && user.admin) &&
                    <Route path="/admin" exact>
                        <Admin />
                    </Route>
                }
            </Switch>
        </Router>
    )
}

const App = () => {
    return (
        <AuthUserProvider>
            <FirebaseProvider>
                <ModalProvider>
                    <Body />
                </ModalProvider>
            </FirebaseProvider>
        </AuthUserProvider>
    )
}

export default App;