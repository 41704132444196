import React, { useContext, useEffect, useState } from 'react';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from './';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../providers/auth-user-provider';
import { useFirebase } from '../firebase';

export const Navigation = (props) => {
    const { ready, user } = useContext(AuthContext);
    const { auth, firestore } = useFirebase();
    const [username, setUsername] = useState(null);

    let location = useLocation();
    const history = useHistory();

    const navigateLogin = () => {
        history.push('/login')
    }

    const signOut = async () => {
        await auth.signOut();
    }

    return (
        <div className='w100 flex justify-end items-center'>
            <div className='font-ubuntu fs-20 lh-23 bold c-primary'>Upgrade</div>
        </div>
    );
};