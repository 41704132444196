import React from "react";

const faqList = [
    {
        title: "Ерөнхий",
        questions: [
            {
                id: "faq1",
                title: "UPGRADE гэж юу вэ?",
                answer: (
                    <p>
                        Цаг хугацаа, орон зайнаас үл хамааран ЗОГСОЛТГҮЙ
                        СУРАЛЦАХ боломжийг бид танд олгож байна. Олон улсын
                        тэргүүн туршлагаас суралцаж, эх орныхоо шилдэг багш
                        нарын хичээлийг нэгтгэн, цоо шинэ агуулга, шинэлэг
                        дүрслэл бүхий контент бүтээж, таны мэдлэг оюуныг
                        тэтгэхэд манай байгууллагын эрхэм зорилго оршино.
                    </p>
                ),
            },
            {
                id: "faq2",
                title: "Элсэлтийн ерөнхий шалгалтын тухай",
                answer: (
                    <div>
                        ЭЛСЭЛТИЙН ЕРӨНХИЙ ШАЛГАЛТ:
                        <br /> <br />
                        <ul>
                            <li>
                                {" "}
                                2006 оноос их, дээд сургуульд элсэгчдээс авч
                                эхэлсэн үндэсний хэмжээний шалгалт юм.{" "}
                            </li>
                            <li>
                                {" "}
                                Монгол хэл, Монгол Улсын түүх, Математик, Газар
                                зүй, Англи хэл, Нийгэм судлал, Хими, Биологи,
                                Физик, Орос хэлний хичээлүүдээр ЭЕШ өгөх
                                боломжтой ба 2014 оноос эхлэн шалгуулагч нэг бүр
                                зайлшгүй Монгол хэл, бичгийн шалгалт өгдөг
                                болсон байна.{" "}
                            </li>
                            <li>
                                {" "}
                                Жилд дунджаар 40 гаруй мянган шалгуулагч ЭЕШ
                                өгдөг.{" "}
                            </li>
                            <li>
                                {" "}
                                Сөүлийн Их Сургуулийн эрдэмтдийн тооцооллоор
                                ахлах сургуулийн төгсөгчид элсэлтийн ерөнхий
                                шалгалтдаа доод тал нь гурван сарын хугацаанд
                                уйгагүй бэлдвэл хүссэн амжилтдаа хүрч чаддаг
                                гэсэн дүгнэлт гаргасан байна.{" "}
                            </li>
                            <li>
                                {" "}
                                Түүнчлэн ганцхан танхимын сургалтаар бус бие
                                даан ганцаарчлан уйгагүй бэлдэх нь өндөр оноо
                                авахад нөлөөлдгийг нотолсон байна.{" "}
                            </li>
                        </ul>
                        Тэгвэл Апгрейд ТАНД:
                        <br /> <br />
                        БУСДААС ЯЛГАРАХ ШИНЭЛЭГ ШИЙДЭЛТЭЙ, ДУУ ДҮРСНИЙ ӨНДӨР
                        ЧАНАРТАЙ ХАМГИЙН ШИЛДЭГ БАГШ НАРЫН ХИЧЭЭЛИЙГ САНАЛ
                        БОЛГОЁ. <br />
                        <br />
                        Бидний давуу тал:
                        <br /> <br />
                        <ul>
                            <li> Хамгийн сүүлийн үеийн техник хэрэгсэл </li>
                            <li> Хамгийн оновчтой арга зүй </li>
                            <li> Хамгийн шилдэг багш нар </li>
                            <li> Хамгийн үр дүнтэй хичээлийн хөтөлбөр </li>
                            <li>
                                {" "}
                                Нэг бүр нь 20 минутаас хэтрэхгүй, 360+ цагийн
                                хичээл{" "}
                            </li>
                        </ul>
                        Та гагцхүү зогсолтгүй суралц.
                    </div>
                ),
            },
            {
                id: "faq3",
                title: "Хэрхэн бүртгүүлэх вэ?",
                answer: (
                    <p>
                        Facebook эсвэл Gmail-ээрээ нэвтэрч ороход л хангалттай.
                    </p>
                ),
            },
            {
                id: "faq4",
                title: "Хэрхэн төлбөр төлөх вэ?",
                answer: (
                    <p>
                        Та нэвтэрч орсныхоо дараа өөрийн сонирхсон хичээлийн
                        багцыг сонгож, зөвхөн QPAY ашиглан төлбөрөө төлнө.
                        Ийнхүү таны төлбөр баталгаажсаны дараа сонгосон хичээлээ
                        тухайн хичээлийн жилийг дуустал тоглуулах боломжтой
                        болно.
                    </p>
                ),
            },
            {
                id: "faq5",
                title: "Холбоо барих",
                answer: (
                    <p>
                        Харилцах утасны дугаар:{" "}
                        <a href="tel:89709085">976-89709085</a>,{" "}
                        <a href="tel:93111123">976-93111123</a>,{" "}
                        <a href="tel:98984533">976-98984533</a>
                        <br />
                        Facebook:{" "}
                        <a
                            href="https://www.facebook.com/upgrade.edutech"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Upgrade.MN
                        </a>
                    </p>
                ),
            },
        ],
    },
];
const Questions = () => {
    const Question = (props) => {
        const { data } = props;

        return (
            <div className="card">
                <div className="card-header">
                    <button
                        type="button"
                        data-toggle="collapse"
                        data-target={`#${data.id}`}
                        aria-expanded="false"
                        aria-controls={data.id}
                    >
                        {data.title}
                    </button>
                </div>

                <div id={data.id} className="collapse edit-content">
                    <div className="content">{data.answer}</div>
                </div>
            </div>
        );
    };

    const QuestionBlock = (props) => {
        const { data } = props;

        return (
            <>
                {/* <h5>{data.title}</h5> */}
                <div className="accordion">
                    {data.questions.map((el, ind) => (
                        <Question key={`faq-question-${ind}`} data={el} />
                    ))}
                </div>
            </>
        );
    };

    return (
        <section className="faq-container">
            <div className="container">
                <h2 className="text-center">Түгээмэл асуултууд</h2>
                <div className="row">
                    <div className="offset-lg-2 col-lg-8">
                        {faqList.map((el, ind) => (
                            <QuestionBlock key={`faq-block-${ind}`} data={el} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export { Questions };
