import React, { createContext, useEffect, useState } from 'react';
import { useFirebase } from '../firebase';

export const ModalContext = createContext({
    modal: false,
    setModal: () => { },
});

export const ModalProvider = ({ children }) => {
    let [modal, setModal] = useState(false);

    return (
        <ModalContext.Provider value={{
            modal,
            setModal,
        }}>
            {children}
        </ModalContext.Provider>
    );
};
